import api from '@apiInstance';

export default {
  add(body) {
    return api.post('section_banners', body);
  },
  get(id) {
    return api.fetch('section_banners/' + id);
  },
  list(params) {
    return api.fetch('/section_banners', { params });
  },
  update(body) {
    return api.patch('section_banners', body);
  },
  delete(id) {
    return api.remove('section_banners', id);
  },
};
