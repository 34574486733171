<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Section Banners"
              url=""
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-update-cancel="(v) => loadHeaderBanners()"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in dataColumns"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                  :cell-template="item.template"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModal"
      centered
      @hide="onCloseModal()"
      size="lg"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-group label="Section" label-for="v-section">
            <b-form-input
              id="v-section"
              v-model="formData.section"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Keyword" label-for="v-keyword">
            <b-form-input
              id="v-keyword"
              v-model="formData.keyword"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="onCloseModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import sectionBannersApi from '@api/section_banners';
import { queryParameters } from '@/schema';

export default {
  name: 'HeaderBannersPage',
  components: {},
  data: () => ({
    showModal: false,
    onFileUpload: null,
    dataSource: [],
    dataColumns: [
      { caption: 'Section', field: 'section' },
      { caption: 'Keyword', field: 'keyword' },
    ],
    totalCount: 0,
    page: 0,
    size: 0,
    formData: {
      preview_start_date: null,
      preview_end_date: null,

      id: 0,
      section: null,
      keyword: null,
    },
  }),
  computed: {
    getImageSource: {
      get() {
        if (+this.$route.query.id) {
          if (this.formData.thumbnailbase64) {
            return this.formData.thumbnailbase64;
          }

          if (!this.formData.thumbnailbase64 && this.formData.banner_image) {
            return this.formData.banner_image;
          }
        }

        return this.formData.thumbnailbase64;
      },
    },
  },
  watch: {
    onFileUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.formData.banner_image = file.name;
          this.formData.thumbnailbase64 = reader.result;
        };
      }
    },
    page(v) {
      this.loadHeaderBanners();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadHeaderBanners();
      }
    },
  },
  mounted() {
    this.loadHeaderBanners();
  },
  methods: {
    loadHeaderBanners() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      sectionBannersApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;
            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      let app;

      this.formData.start_date = this.inputDateTimeToUnix(
        this.formData.preview_start_date
      );
      this.formData.end_date = this.inputDateTimeToUnix(
        this.formData.preview_end_date
      );

      if (!this.formData.id) {
        app = sectionBannersApi.add(this.formData);
      } else {
        app = sectionBannersApi.update(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            if (data) {
              this.onCloseModal();
              this.loadHeaderBanners();
            }
          })
          .catch((err) => {
            if (err) {
              this.toastConfig('', 'error');
            }
          })
          .finally(() => {
            this.toastConfig();
          });
      }
    },
    onEdit(item) {
      item.preview_start_date = this.inputDateTimeFromUnix(item.start_date);
      item.preview_end_date = this.inputDateTimeFromUnix(item.end_date);

      this.formData = item;
    },
    onDelete(event) {
      sectionBannersApi
        .delete(event)
        .then(() => {
          this.loadHeaderBanners();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onCloseModal() {
      this.showModal = false;

      this.formData = {
        id: 0,
        start_date: null,
        end_date: null,
        header: null,
        subheader: null,
        button_label: null,
        button_url: null,
        banner_image: null,
        section: null,
        keyword: null,
        thumbnailbase64: null,
      };
    },
    onOpenModal(event) {
      this.showModal = true;
      const { name, data } = event;

      if (name === 'edit') {
        this.onEdit(data);
      }
    },
  },
};
</script>

<style></style>
